<template>
  <div class="operate111" ref="vlbox">
    <div class="index_main" ref="index_main">
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>确定删除该条订单吗?</span>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button size="small" type="primary" @click="deletePro"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <div class="index_right">
        <p class="search">
          <el-select v-if="permissoin.includes('query')"  clearable v-model="searchForm.status" size="mini">
            <el-option
              v-for="item in option1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
          v-if="permissoin.includes('query')"
            type="text" clearable
            size="mini"
            v-model="searchForm.keywords"
            placeholder=" 请输入关键字"
          ></el-input>
          <button
            class="btn1"
            v-if="permissoin.includes('query')"
            @click="
              currentPage2 = 1;
              getData();
            "
          >
            <i class="el-icon-search"></i>
            查询
          </button>
          <button
            class="btn2"
            v-if="permissoin.includes('add')"
            @click="
              modal = 1;
              modalState = 0;form.status=-2
            "
          >
            <i class="el-icon-plus"></i>
            新增
          </button>
          <button
            class="btn1"
            v-if="permissoin.includes('export')"
            @click="textExport"
          >
            <i class="el-icon-upload2"></i>
            导出
          </button>
        </p>
        <div class="table srcollstyle">
          <el-table :data="tableData" style="width: 98%" border>
            <el-table-column type="index" label="排序" width="80">
              <template slot-scope="scope">
                       {{(currentPage2 - 1) * 10 + scope.$index + 1}}
                    </template>
            </el-table-column>
            <el-table-column prop="projectName" label="项目名称" :show-overflow-tooltip="true"
              width="250">
            </el-table-column>
            <el-table-column prop="areaName" label="项目区域">
              <template slot-scope="scope">
              {{areaNameChange(scope.row.areaName)}}
              </template>
            </el-table-column>
            <el-table-column prop="contractorsName" label="参建单位"      :show-overflow-tooltip="true"
              width="250">
            </el-table-column>
            <el-table-column prop="linkman" label="联系人"> </el-table-column>
            <el-table-column prop="phone" label="联系电话"> </el-table-column>
            <el-table-column prop="creatorName" label="申请人">
            </el-table-column>
            <el-table-column prop="allPrice" label="订单总价" :show-overflow-tooltip="true"
              width="100" v-if="permissoin.includes('money')">
              <template slot-scope="scope">
                  <span class="colorred">￥{{fmoney(scope.row.allPrice,2)}}</span>
                    </template>
            </el-table-column>
            <el-table-column label="计划状态">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 1" class="colororange">待审核</span>
                <span v-if="scope.row.status == 2" class="colorred">驳回</span>
                <span v-if="scope.row.status == -1">已撤回</span>
                <span v-if="scope.row.status == 0" class="colorgreen">已完成</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-dropdown trigger="click" @command="handleCommand">
                  <el-button type="primary" plain size="small">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                    v-if="permissoin.includes('query')"
                      icon="el-icon-search"
                      :command="{ num: 2, data: scope.row }"
                      >查看</el-dropdown-item>
                    <el-dropdown-item
                    v-show="scope.row.status==1&&permissoin.includes('examine')"
                      icon="el-icon-edit-outline"
                      :command="{ num: 1, data: scope.row }"
                      >审核</el-dropdown-item>
                       <el-dropdown-item
                      icon="el-icon-refresh-left"
                     v-show="scope.row.status == 1&&permissoin.includes('withdraw')"
                      :command="{ num: -1, data: scope.row }"
                      >撤回</el-dropdown-item
                    >
                    <el-dropdown-item
                    icon="el-icon-delete" 
                    v-show="scope.row.status==1&&permissoin.includes('delete')"
                      :command="{ num: 3, data: scope.row }"
                      >删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage2"
            prev-text="上一页"
            next-text="下一页"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="modal" v-show="modal == 1">
      <div class="inner">
        <p class="title">
          <span v-if="modalState == 0">新增订单</span>
          <span v-else>订单详情</span>
          <span
            @click="
              modal = 0;
              netArrUpdate = [{ key: 0,productName:'运维服务（单位:月）',operNumber:'12' }];
              form = { allPrice: 0,allTax:0,auditOpinion:'' };
            "
            class="pointer"
          >
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="modal_center scrollbar_width0">
          <p>
            <span class="spaa"><span class="star">* </span>项目名称</span>
            <el-select
              v-model="form.projectId"
              clearable filterable
              size="mini"
              placeholder="请选择"
              :disabled="modalState!=0"
            >
              <el-option
                v-for="item in option5"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </p>
          <div class="text_item">
            <span>*</span>其他服务
          </div>
          <div class="jstitle">
            <span>名称</span>
            <span>数量</span>
            <span>税率（%）</span>
            <span>含税单价</span>
          </div>
          <div
            class="net"
            v-for="item in netArrUpdate"
            :key="item.key"
          >
            <span class="el-select">
              <el-input
              v-model="item.productName"
              disabled
              placeholder="请输入"
              size="mini"
            ></el-input>
            </span>
            <el-input
              v-model="item.operNumber"
              placeholder="请输入"
              size="mini"
            ></el-input>
            <el-input
              v-model="item.taxRate"
                size="mini"
                placeholder="整数如13"
            ></el-input>
            <el-input
            v-show="permissoin.includes('money')"
              v-model="item.taxUnitPrice"
              placeholder="请输入"
              size="mini"
            ></el-input>
          </div>
          <p class="disabledred" v-show="permissoin.includes('money')">
            <span class="spaa"><span class="star">* </span>税金（元）</span>
            <el-input v-model="form.allTax" disabled size="mini"></el-input>
          </p>
          <p class="disabledred" v-show="permissoin.includes('money')">
            <span class="spaa"><span class="star">* </span>总价（元）</span>
            <el-input disabled v-model="form.allPrice" size="mini"></el-input>
          </p>
              <p
              style="display: flex"
              v-show="form.status == 1 || form.status == 2"
            >
              <span class="spa" style="width:96px">审批意见</span>
              <el-input
              type="textarea"
                v-model="form.auditOpinion"
                :disabled='form.status == 2'
                size="mini"
                style="color: #d62829"
              ></el-input>
            </p>
        </div>
        <div class="bottom">
          <button class="btn_red" v-show="modalState==0&&form.status==-2" @click="onsubmit">保存</button>
            <button class="btn_red" v-if="modalState == 0&&form.status==-1" @click="onsubmit10">
            提交
          </button>
           <button class="btn_gray" v-show="modalState==0" @click="modal = 0;netArrUpdate = [{ key: 0,productName:'运维服务（一年）' }];
              form = { allPrice: 0,allTax:0,auditOpinion:'' };">取消</button>
          <button class="btn_red" v-show="modalState==1" @click="onsubmit1(0)">通过</button>
          <button class="btn_gray" v-show="modalState==1" @click="onsubmit1(2)">驳回</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import {
  renewGetList,userGetAll,
  renewAdd,renewDelete,axiosUrl,
  wxGetOrgList,
  productGetList,
  renewGet,
} from "@/api/apis.js";
import { areaNameChange } from "@/utils/getTime.js";

export default {
  data() {
    return {
      deleteId:'',
      options: [],
      option4: [],
      option2: [],
      option3: [],
      areaNameChange,
      option5: [],
      option6: [],
      option8: [],
      option7: [{id:1,name:'现金支付'},{id:2,name:'线上支付'}],
      modalState: 0,
      srcList:[],
      form: { allPrice: 0,projectId:'',auditOpinion:'' },
      info: 1,
      sn: "商务计划",
      currentPage2: 1,
      treeData: [],
      hide: 1,
      modal: 0,
      treeList: [],
      total: 0,
      searchForm: {},
      permissoin: [],
      projectP: -1,
      dialogVisible: false,
      netArrUpdate: [{ key: 0,productName:'运维服务（单位:月）',operNumber:'12' }],
      ruleForm: {
        pwd: 123456,
      },
      props: {
        label: "name",
        value: "id",
        children: "children",
        checkStrictly: true,
      },
      option1: [
        {
          value: "1",
          label: "待审核",
        },{
          value: "-1",
          label: "已撤回",
        },
        {
          value: "0",
          label: "已完成",
        },
        {
          value: "2",
          label: "被驳回",
        },
      ],
      tableData: [],
      rules: {
        orgId: [{ required: true, message: "选择机构", trigger: "blur" }],
      },
    };
  },
  methods: {
    getData() {
      let data = {
        status: this.searchForm.status,
        keywords: this.searchForm.keywords,
        pageNum: this.currentPage2,
        pageSize: 10,
      };
      renewGetList(data).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total - 0;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
    fmoney(val) {
      if(val){
 //金额转换 分->元 保留2位小数 并每隔3位用逗号分开 1,234.56
        var str = val.toFixed(2) + '';
        var intSum = str.substring(0,str.indexOf(".")).replace( /\B(?=(?:\d{3})+$)/g, ',' );//取到整数部分
        var dot = str.substring(str.length,str.indexOf("."))//取到小数部分搜索
        var ret = intSum + dot;
        return ret;
      }else{
        return '0'
      }    
    },
     deletePro(){
      renewDelete(this.deleteId).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message:'删除成功',
          });
          this.dialogVisible = false;
          this.getData();
        } else {
          this.$message.error(res.data.info);
        }
      });
    },
    textExport(){
             let data = {  status: this.searchForm.status,
        keywords: this.searchForm.keywords,};
      location.href =axiosUrl +"/admin/l/order/renew/export?"+qs.stringify(data);
    },
    handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getData();
    },
 //审核
    onsubmit1(i){
      renewAdd({id:this.form.id,status:i,auditOpinion:this.form.auditOpinion}).then(res=>{
          if(res.code==200){
            this.$message({
            type: "success",
            message: res.info,
          });
              this.getData()
            this.modal = 0;
          this.netArrUpdate = [{ key: 0,productName:'运维服务（一年）',operNumber:'12' }];
          this.form = { allPrice: 0 ,allTax:0};
        } else {
          this.$message.error(res.data.info);
        }
      })
    },
    onsubmit() {
      this.netArrUpdate=JSON.parse(JSON.stringify(this.netArrUpdate)).map(item=>{
        item.taxRate=item.taxRate/100
          return item
      })
      let data = {
        projectId: this.form.projectId,
        materialDate: this.form.materialDate,
        allPrice: this.form.allPrice,
        payWay: this.form.payWay,
        description: this.form.description,
        materialId: this.form.materialId,
        itemList: this.netArrUpdate,
      };
       renewAdd(data).then((res) => {
        if (res.code == 200) {
          this.getData();
          this.$message({
            type: "success",
            message: res.info,
          });
          this.modal = 0;
          this.netArrUpdate = [{ key: 0,productName:'运维服务（单位:月）',operNumber:'12' }];
          this.form = { allPrice: 0 ,allTax:0};
        } else {
          this.$message.error(res.data.info);
        }
      });
    },
     onsubmit10() {
      this.netArrUpdate=JSON.parse(JSON.stringify(this.netArrUpdate)).map(item=>{
        item.taxRate=item.taxRate/100
          return item
      })
      let data = {
        projectId: this.form.projectId,
        materialDate: this.form.materialDate,
        allPrice: this.form.allPrice,
        payWay: this.form.payWay,
        description: this.form.description,
        materialId: this.form.materialId,
        itemList: this.netArrUpdate,
        id:this.deleteId,
        status:1,
      };
       renewAdd(data).then((res) => {
        if (res.code == 200) {
          this.getData();
          this.$message({
            type: "success",
            message: res.info,
          });
          this.modal = 0;
          this.netArrUpdate = [{ key: 0,productName:'运维服务（单位:月）',operNumber:'12' }];
          this.form = { allPrice: 0 ,allTax:0};
        } else {
          this.$message.error(res.data.info);
        }
      });
    },
    handleCommand(d) {
      this.deleteId=d.data.id
      this.form.id = d.data.id;
      if (d.num == 2 || d.num == 1) {
        //查看详情
         renewGet(d.data.id).then((res) => {
          if (res.code == 200) {
            if(d.data.status==-1){
              this.modalState = 0
            }else{
            this.modalState = d.num;
            }
            
            this.modal = 1;
            this.form=res.data
            if (d.data.status == 2) {
              this.form.auditOpinion = res.data.auditOpinion;
            }
            
            this.netArrUpdate = res.data.itemList.map(item=>{item.taxRate=item.taxRate*100 
            return item});
          } else {
            this.$message.error(res.data.info);
          }
        });
      }else if (d.num == 3) {
        
        this.dialogVisible = true;
      }else if(d.num==-1){
          renewAdd({id:this.form.id,status:-1}).then(res=>{
          if(res.code==200){
            this.$message({
            type: "success",
            message: res.info,
          });
              this.getData()
            this.modal = 0;
          this.netArrUpdate = [{ key: 0,productName:'运维服务（一年）',operNumber:'12' }];
          this.form = { allPrice: 0 ,allTax:0};
        } else {
          this.$message.error(res.data.info);
        }
      })
      }
    },
  },
  watch: {
    netArrUpdate: {
      deep: true, //深度监听
      handler: function () {
        let num1 = 0;
        let num2 = 0;
        this.netArrUpdate.forEach((item) => {
          num1 += (item.taxUnitPrice - 0) * (item.operNumber - 0);
          num2 += (item.taxUnitPrice - 0) * (item.operNumber - 0)* (item.taxRate- 0)/(100+(item.taxRate-0));
        });
        this.form.allPrice = isNaN(num1) ? 0 : num1;
        this.form.allTax = (isNaN(num2) ? 0 : num2).toFixed(2);
      },
    },
  },
  mounted() {
    //获取权限
    let arr = JSON.parse(localStorage.getItem('perArr'))
    let routePath =this.$route.path
    arr.forEach(item=>{
      if(item.purl==routePath){
        this.permissoin.push(item.permission)
      }
    })
    this.getData();
    wxGetOrgList({pageSize:0}).then((res) => {
      if (res.code == 200) {
        this.option5 = res.data.list;
      }
    });
    productGetList({pageSize:0}).then((res) => {
      this.option4 = res.data.list.filter((item) => item.type == 3);
      this.option6 = res.data.list.filter(
        (item) => item.type == 1 || item.type == 2
      );
    });
    userGetAll({pageSize:0}).then((res) => {
      if (res.code == 200) {
        this.option8 = res.data.list;
      }
    });
  },
};
</script>

<style lang="less" >
.operate111 {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main {
    flex: 1;
    background-color: #fff;
    display: flex;
  }
  .index_right {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    box-sizing: border-box;
    .search {
      margin: 22px 0;
      .el-input {
        width: 208px;
        margin-right: 20px;
      }
    }
    .table {
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide {
      position: absolute;
      top: 48%;
      left: 0px;
      cursor: pointer;
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      width: 500px;
      max-height: 800px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px #888888;
      .spa {
  display: inline-block;
  width: 80px;
  position: relative;
  left: 10px;
}
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center {
        flex: 1;
        padding-left: 34px;
        box-sizing: border-box;
        padding-top: 20px;
        .el-button--mini,
        .el-button--mini.is-round {
          padding: 6px 12px;
          margin-left: 4px;
        }
        .jstitle {
          margin-bottom: 12px;
          span {
            display: inline-block;
            margin-right: 12px;
          }
          & > span:nth-child(1) {
            width: 150px;
          }
          & > span:nth-child(2) {
            width: 80px;
          }
          & > span:nth-child(3) {
            width: 80px;
          }
          & > span:nth-child(4) {
            width: 80px;
          }
          & > span:nth-child(5) {
            width: 80px;
          }
        }
        .text_item {
          margin-bottom: 12px;
          & > span:nth-child(1) {
            color: #f56c6c;
            margin-right: 2px;
          }
          & > span:nth-child(2) {
            display: inline-block;
            margin-left: 198px;
            cursor: pointer;
            color: #d62829;
            i {
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
        .net {
          .line {
            border-top: 1px dashed rgb(221, 214, 214);
            width: 388px;
          }
          p {
            margin-bottom: 20px;
          }
          .spaa {
            display: inline-block;
            width: 80px;
          }
          .el-select {
            width: 150px;
            margin-right: 12px;
            .el-input {
              width: 150px;
            }
          }
          .el-input {
            width: 80px;
            margin-right: 12px;
            .el-input__inner {
              border: none;
              padding: 0;
            }
          }
          .star {
            color: #ff2825;
            font-size: 14px;
            position: relative;
            top: 2px;
          }
        }

        .line {
          border-top: 1px dashed rgb(221, 214, 214);
          width: 348px;
        }
        p {
          margin-bottom: 20px;
        }
        .spaa {
          display: inline-block;
          width: 80px;
        }
        .el-input {
          width: 300px;
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
      }
      .bottom {
        height: 60px;
        display: flex;
        justify-content: center;
        .btn_red {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>